import { NgxPaginationModule } from 'ngx-pagination';
import { AdminSettingsModule } from './../admin-settings/admin-settings.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminAccountsRoutingModule } from './admin-accounts-routing.module';
import { MngIndividualComponent } from './mng-individual/mng-individual.component';
import { MngCompanyComponent } from './mng-company/mng-company.component';
import { MngCardComponent } from './mng-card/mng-card.component';
import { MngVehicleComponent } from './mng-vehicle/mng-vehicle.component';
import { SidebarAccountsComponent } from './sidebar-accounts/sidebar-accounts.component';
import { MngAccCardsComponent } from './mng-acc-cards/mng-acc-cards.component';
import { AccountTransactionsComponent } from './account-transactions/account-transactions.component';
import { NewAccComponent } from './new-acc/new-acc.component';


@NgModule({
  declarations: [MngIndividualComponent, MngCompanyComponent, MngCardComponent, MngVehicleComponent, SidebarAccountsComponent, MngAccCardsComponent, AccountTransactionsComponent, NewAccComponent],
  imports: [
    CommonModule,
    AdminAccountsRoutingModule,
    ReactiveFormsModule,
    AdminSettingsModule,
    NgxPaginationModule
  ]
})
export class AdminAccountsModule { }
