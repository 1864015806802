<div class="az-content pd-y-20 pd-lg-y-30 pd-xl-y-40">
    <div class="container">
        <app-sidebar-accounts></app-sidebar-accounts>
        <!-- az-content-left -->
      
      <div class="az-content-body pd-lg-l-40 d-flex flex-column">
        <div class="az-content-breadcrumb">
          <span>Accounts</span>
          <span>Company</span>
        </div>
        <h2 class="az-content-title">Company Accounts</h2>

        <div class="row">
          <div class="col-md-5">
            <div class="az-content-label mg-b-5">Company Accounts List</div>
            <p class="mg-b-20">List of all Company Accounts.</p>
          </div>
          <div class="col-md-5 mt-md-0 mt-3" align="right">
            <div class="input-group">
              <span class="input-group-btn">
                <span class="text-danger pointer btn-sm" title="Cancel Search" *ngIf="accounts2" (click)="switchDisplay()" type="button"><i class="fa fa-times"></i></span>
              </span>
              <select class="form-control w-25" id="searchType">
                <option value="" disabled selected>choose</option>
                <option value="name">Name</option>
                <option value="card">Card</option>
              </select>
              <input type="text" class="form-control w-50" id="searchValue" placeholder="Search account...">
              <span class="input-group-btn">
                <button class="btn btn-sm btn-outline-primary" (click)="searchAccount()" type="button"><i class="fa fa-search"></i></button>
              </span>
              <div class="spinner-grow text-primary ml-2" *ngIf="loading" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <div class="col-md-2 mt-md-0 mt-3" align="right">
            <button class="btn btn-outline-success btn-rounded btn-sm" data-toggle="modal" data-target="#add">
              NEW &nbsp;<i class="fa fa-plus-circle"></i>
            </button>
          </div>
        </div>

        <div class="table-responsive mt-4" *ngIf="!accounts2">
          <table class="table table-hover mg-b-0">
            <thead>
              <tr>
                <th>Account Number</th>
                <th>Created Date</th>
                <th>Name</th>
                <th>Contact</th>
                <!-- <th>Email</th> -->
                <th>Registration Number</th>
                <th>Cards</th>
                <th>Balance</th>
                <th>Managers</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody *ngIf="accounts">
              <tr *ngFor="let r of accounts | paginate: config;">
                <th>{{r.accountNumber}}</th>
                <th>{{r.registrationDate | date: 'short'}}</th>
                <td>{{r.companyName}}</td>
                <td>{{r.contactNumber}}</td>
                <!-- <td>{{r.email}}</td> -->
                <td>{{r.registrationNumber}}</td>
                <td>
                  <span class="text-info pointer" title="Manage Account Vehicles" (click)="openAccountCards(r.accountNumber)">
                    <i class="fa fa-credit-card"></i>
                  </span>
                </td>
                <td>
                  {{r.balance | currency: 'USD' : 'symbol' : '1.2-2'}} &nbsp; 
                  <span class="text-primary pointer" title="Topup Funds" (click)="topUpAccount(r.accountNumber)">
                    <i class="fa fa-plus-circle"></i>
                  </span>
                </td>
                <td>
                  <span class="text-info pointer" title="Manage Account Managers" (click)="openAccountManagers(r.id)">
                    <i class="fa fa-users-cog"></i>
                  </span>
                </td>
                <td>
                  <span class="text-info pointer" title="Account Transactions" (click)="openTransactions(r.accountNumber, r.companyName)">
                    <i class="fa fa-money-bill-wave"></i>
                  </span>
                  <span class="text-primary pointer ml-2" title="Update Record" (click)="loadAccount(r.accountNumber)">
                    <i class="fa fa-edit"></i>
                  </span>
                  <span class="text-danger pointer ml-2" title="Delete Record" (click)="selectAccount(r.accountNumber)">
                    <i class="fa fa-trash-alt"></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <br>
          <span *ngIf="accounts" id="entriesCounter" class="text-secondary float-right">Showing {{ (page - 1) * pageSize + 1 }} - {{ showin }} of {{ config.totalItems }}.</span>
          <br>
          <div *ngIf="accounts">
              <div class="d-flex" id="controlsArea">
                  Items:
                  <select class="pageItems" (change)="handlePageSizeChange($event)">
                      <option *ngFor="let size of pageSizes" [ngValue]="size">
                          {{ size }}
                      </option>
                  </select>
                  <pagination-controls
                  class=""
                  responsive="true"
                  (pageChange)="handlePageChange($event)"
                  ></pagination-controls> 
              </div> 
          </div>
        </div><!-- table-responsive -->

        <div class="table-responsive mt-4" *ngIf="accounts2">
          <table class="table table-hover mg-b-0">
            <thead>
              <tr>
                <th>Account Number</th>
                <th>Created Date</th>
                <th>Name</th>
                <th>Contact</th>
                <th>Email</th>
                <th>Registration Number</th>
                <th>Cards</th>
                <th>Balance</th>
                <th>Managers</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody *ngIf="accounts2">
              <tr *ngFor="let r of accounts2 | paginate: config;">
                <th>{{r.accountNumber}}</th>
                <th>{{r.registrationDate}}</th>
                <td>{{r.companyName}}</td>
                <td>{{r.contactNumber}}</td>
                <td>{{r.email}}</td>
                <td>{{r.registrationNumber}}</td>
                <td>
                  <span class="text-info pointer" title="Manage Account Vehicles" (click)="openAccountCards(r.accountNumber)">
                    <i class="fa fa-credit-card"></i>
                  </span>
                </td>
                <td>
                  {{r.balance}} &nbsp; 
                  <span class="text-primary pointer" title="Topup Funds" (click)="topUpAccount(r.accountNumber)">
                    <i class="fa fa-plus-circle"></i>
                  </span>
                </td>
                <td>
                  <span class="text-info pointer" title="Manage Account Managers" (click)="openAccountManagers(r.id)">
                    <i class="fa fa-users-cog"></i>
                  </span>
                </td>
                <td>
                  <span class="text-info pointer" title="Account Transactions" (click)="openTransactions(r.accountNumber, r.companyName)">
                    <i class="fa fa-money-bill-wave"></i>
                  </span>
                  <span class="text-primary pointer ml-2" title="Update Record" (click)="loadAccount(r.accountNumber)">
                    <i class="fa fa-edit"></i>
                  </span>
                  <span class="text-danger pointer ml-2" title="Delete Record" (click)="selectAccount(r.accountNumber)">
                    <i class="fa fa-trash-alt"></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div><!-- table-responsive -->

        <div class="ht-40"></div>

        
      </div><!-- az-content-body -->
    
    </div><!-- container -->
</div><!-- az-content -->

<!-- Modals -->
<div class="modal fade" id="add" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">CREATE ACCOUNT</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <form [formGroup]="addForm" (ngSubmit)="addAccount(addForm)">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    Company Name
                    <input type="text" formControlName="companyName" placeholder="Name" required class="form-control">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    Industry
                    <select formControlName="industry" class="form-control" required>
                      <option value="" selected disabled>choose...</option>
                      <option *ngFor="let i of industries" value="{{i.industry}}">{{i.description}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    Navision Account Number
                    <input type="text" formControlName="navisionAccountNumber" placeholder="Account Number" required class="form-control">
                  </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                      Email
                      <input type="email" formControlName="email" placeholder="Email" required class="form-control">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                      Contact Number
                      <input type="text" formControlName="contactNumber" placeholder="Contact Number" required class="form-control">
                    </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    Registration Number
                    <input type="text" formControlName="registrationNumber" placeholder="Registration Number" required class="form-control">
                  </div>
              </div>
              </div>
            </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-danger" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-primary" type="submit"><i class="fa fa-save"></i>&nbsp; Add Account</button>
            </div>
          </form>
        </section>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="edit" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">UPDATE ACCOUNT</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <form [formGroup]="editForm" (ngSubmit)="updateAccount(editForm)">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    Account Number
                    <input type="text" formControlName="accountNumber" placeholder="Account Number" required class="form-control">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    Company Name
                    <input type="text" formControlName="companyName" placeholder="Name" required class="form-control">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    Industry
                    <select formControlName="industry" class="form-control" required>
                      <option value="" selected disabled>choose...</option>
                      <option *ngFor="let i of industries" value="{{i.industry}}">{{i.description}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    Navision Account Number
                    <input type="text" formControlName="navisionAccountNumber" placeholder="Account Number" required class="form-control">
                  </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                      Email
                      <input type="email" formControlName="email" placeholder="Email" required class="form-control">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                      Contact Number
                      <input type="text" formControlName="contactNumber" placeholder="Contact Number" required class="form-control">
                    </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    Registration Number
                    <input type="text" formControlName="registrationNumber" placeholder="Registration Number" required class="form-control">
                  </div>
              </div>
              </div>
            </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-danger" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-primary" type="submit"><i class="fa fa-save"></i>&nbsp; Update Account</button>
            </div>
          </form>
        </section>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">DELETE ACCOUNT</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <div>
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h2>You are about to delete an Account!</h2>
                </div>
              </div>
            </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-primary" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-danger" type="button" (click)="deleteAccount()"><i class="fa fa-trash-alt"></i>&nbsp; Delete Account</button>
            </div>
          </div>
        </section>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="topup" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">TOPUP ACCOUNT</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <form [formGroup]="topupForm" (ngSubmit)="topupAccount(topupForm)">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    Source
                    <select formControlName="source" class="form-control" required>
                      <option value="" selected disabled>choose...</option>
                      <option value="BANK">Bank</option>
                      <option value="CASH">Cash</option>
                      <option value="DEBIT_JOURNAL">Debit Journal</option>
                      <option value="CREDIT_JOURNAL">Credit Journal</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                      Currency
                      <input readonly type="text" formControlName="currency" placeholder="Currency" required class="form-control">
                    </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    Amount
                    <input type="text" formControlName="amount" placeholder="Amount" required class="form-control">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    Transaction Reference
                    <input type="text" formControlName="transactionReference" placeholder="Transaction Reference" required class="form-control">
                  </div>
                </div>
              </div>
              </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-danger" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-primary" type="submit"><i class="fa fa-save"></i>&nbsp; Topup Account</button>
            </div>
          </form>
        </section>
      </div>      
    </div>
  </div>
</div>