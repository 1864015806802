import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SupervisorRoutingModule } from './supervisor-routing.module';
import { SupervisorContainerComponent } from './supervisor-container/supervisor-container.component';
import { SupervisorHomeComponent } from './supervisor-home/supervisor-home.component';
import { SupervisorTransComponent } from './supervisor-trans/supervisor-trans.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [SupervisorContainerComponent, SupervisorHomeComponent, SupervisorTransComponent],
  imports: [
    CommonModule,
    SupervisorRoutingModule,
    SharedModule
  ]
})
export class SupervisorModule { }
