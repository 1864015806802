<div class="az-content pd-y-20 pd-lg-y-30 pd-xl-y-40">
    <div class="container">
        <app-sidebar-stations></app-sidebar-stations>
        <!-- az-content-left -->
      
      <div class="az-content-body pd-lg-l-40 d-flex flex-column">
        <div class="az-content-breadcrumb">
          <span>Stations</span>
          <span>Station</span>
          <span>Attendant</span>
        </div>
        <h2 class="az-content-title">Service Station Attendants</h2>

        <div class="row">
          <div class="col-md-8">
            <div class="az-content-label mg-b-5">All Station Attendants List</div>
            <p class="mg-b-20">List of all registered Attendants.</p>
          </div>
          <div class="col-md-4 mt-md-0 mt-3" align="right">
            <button class="btn btn-outline-success btn-rounded btn-sm" data-toggle="modal" data-target="#add">
              NEW &nbsp;<i class="fa fa-plus-circle"></i>
            </button>
          </div>
        </div>

        <div class="table-responsive mt-4">
          <table class="table table-hover mg-b-0">
            <thead>
              <tr>
                <th>Ref</th>
                <th>Name</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>Role</th>
                <th>Station</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let r of attendants;">
                <th>{{r.id}}</th>
                <th>{{r.firstName}} {{r.lastName}}</th>
                <td>{{r.mobile}}</td>
                <td>{{r.email}}</td>
                <td>{{r.role}}</td>
                <td>{{r.serviceStation.stationName}}</td>
                <td>
                  <span *ngIf="r.status == 'Active'" class="badge badge-success">active</span>
                  <span *ngIf="r.status != 'Active'" class="badge badge-danger">inactive</span>
                </td>
                <td>
                  <span class="text-info pointer" title="View Attendant Transactions" (click)="viewTransactions(r.id, r.firstName + ' ' + r.lastName)">
                    <i class="fa fa-money-bill-wave"></i>
                  </span>
                  <span *ngIf="r.status == 'Active'" class="text-danger pointer ml-2" title="Deactivate Attendant" (click)="activateAttendant(r.id, false)">
                    <i class="fa fa-user-times"></i>
                  </span>
                  <span *ngIf="r.status != 'Active'" class="text-success pointer ml-2" title="Activate Attendant" (click)="activateAttendant(r.id, true)">
                    <i class="fa fa-user-check"></i>
                  </span>
                  <span class="text-primary pointer ml-2" title="Update Record" (click)="loadAttendant(r.id, 'edit')">
                    <i class="fa fa-edit"></i>
                  </span>
                  <span class="text-warning pointer ml-2" title="Transfer Attendant" (click)="loadAttendant(r.id, 'transfer')">
                    <i class="fa fa-recycle"></i>
                  </span>
                  <span class="text-danger pointer ml-2" title="Delete Record" (click)="selectAttendant(r.id)">
                    <i class="fa fa-trash-alt"></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div><!-- table-responsive -->

        <div class="ht-40"></div>

        
      </div><!-- az-content-body -->
    
    </div><!-- container -->
</div><!-- az-content -->

<!-- Modals -->
<div class="modal fade" id="add" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">CREATE ATTENDANT</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <form [formGroup]="addForm" (ngSubmit)="addAttendant(addForm)">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    First Name
                    <input type="text" formControlName="firstName" placeholder="Name" required class="form-control">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    Last Name
                    <input type="text" formControlName="lastName" placeholder="Surname" required class="form-control">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    Email
                    <input type="email" formControlName="email" placeholder="Email" required class="form-control">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    Mobile
                    <input type="text" formControlName="mobile" placeholder="Mobile" required class="form-control">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    Role
                    <select formControlName="role" class="form-control" required>
                      <option value="" selected disabled>choose...</option>
                      <option value="ORDINARY">Ordinary</option>
                      <option value="SUPERVISOR">Supervisor</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-danger" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-primary" type="submit"><i class="fa fa-save"></i>&nbsp; Add Attendant</button>
            </div>
          </form>
        </section>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="edit" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">UPDATE ATTENDANT</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <form [formGroup]="editForm" (ngSubmit)="updateAttendant(editForm)">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    First Name
                    <input type="text" formControlName="firstName" placeholder="Name" required class="form-control">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    Last Name
                    <input type="text" formControlName="lastName" placeholder="Surname" required class="form-control">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    Email
                    <input type="email" formControlName="email" placeholder="Email" required class="form-control">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    Mobile
                    <input type="text" formControlName="mobile" placeholder="Mobile" required class="form-control">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    Role
                    <select formControlName="role" class="form-control" required>
                      <option value="" selected disabled>choose...</option>
                      <option value="ORDINARY">Ordinary</option>
                      <option value="SUPERVISOR">Supervisor</option>
                    </select>
                  </div>
                </div>
              </div>
              </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-danger" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-primary" type="submit"><i class="fa fa-save"></i>&nbsp; Update Attendant</button>
            </div>
          </form>
        </section>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="transfer" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-center w-100" id="">TRANSFER ATTENDANT</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <section>
            <form>
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <h4>Choose new station</h4>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <select id="serviceStationId" class="form-control" required>
                        <option value="" selected disabled>choose station</option>
                        <option *ngFor="let s of stations" value="{{s.id}}" [disabled]="checkStation(s.id)">{{s.stationName}}</option>
                      </select>
                    </div>
                  </div>
                </div>  
              </div>
              <br><br>
              <div align="center">
                <button class="btn btn-sm btn-rounded btn-outline-danger" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
                <button class="ml-2 btn btn-rounded btn-sm btn-outline-primary" type="button" (click)="transferAttendant()"><i class="fa fa-save"></i>&nbsp; Transfer Attendant</button>
              </div>
            </form>
          </section>
        </div>      
      </div>
    </div>
  </div>

<div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">DELETE ATTENDANT</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <div>
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h2>You are about to delete an Attendant!</h2>
                </div>
              </div>
            </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-primary" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-danger" type="button" (click)="deleteAttendant()"><i class="fa fa-trash-alt"></i>&nbsp; Delete Attendant</button>
            </div>
          </div>
        </section>
      </div>      
    </div>
  </div>
</div>