import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from '../shared/components/not-found/not-found.component';
import { AuthGuard } from '../shared/utilities/auth.guard';
import { SupervisorContainerComponent } from './supervisor-container/supervisor-container.component';
import { SupervisorHomeComponent } from './supervisor-home/supervisor-home.component';
import { SupervisorTransComponent } from './supervisor-trans/supervisor-trans.component';

const routes: Routes = [
  {
    path: 'supervisor',
    component: SupervisorContainerComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: SupervisorHomeComponent },
      { path: 'transactions', component: SupervisorTransComponent },
      { path: 'station', loadChildren: () => import('./sup-stations/sup-stations.module').then(m => m.SupStationsModule) },

      // not found
      { path: '**', component: NotFoundComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SupervisorRoutingModule { }
