<div class="az-content pd-y-20 pd-lg-y-30 pd-xl-y-40">
    <div class="container">
        <!-- <app-acc-trans-sidebar></app-acc-trans-sidebar> -->
        <!-- az-content-left -->
      
      <div class="az-content-body pd-lg-l-40 d-flex flex-column">
        <div class="az-content-breadcrumb ml-lg-0 ml-4">
          <span>Transactions</span>
          <span>All</span>
        </div>
        <h2 class="az-content-title">
          Station Transactions Summary
        </h2>

        <div class="row">
          <div class="col-md-8 pl-5 pl-lg-3">
            <div class="az-content-label mg-b-5">Transactions</div>
            <p class="mg-b-20">List of all Transactions.</p>
          </div>
          <div class="col-md-4 mt-md-0 mt-3" align="right">
            <!-- <button class="btn btn-outline-success btn-rounded btn-sm" data-toggle="modal" data-target="#add">
              NEW &nbsp;<i class="fa fa-plus-circle"></i>
            </button> -->
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 text-right"></div>
          <section class="col-md-8 row">
            <div class="col-md-4">
              <div class="form-group">
                Start Date
                <input type="date" id="startDate" (change)="handleDateSelection()" class="form-control">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                End Date
                <input type="date" id="endDate" (change)="handleDateSelection()" class="form-control">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                &nbsp; <br>
                <div class="dropdown">
                  <button class="btn btn-sm btn-outline-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <i class="fa fa-file-download"></i> &nbsp; Export
                  </button>
                  <div class="dropdown-menu tx-13" aria-labelledby="dropdownMenuButton" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 40px, 0px);">
                    <a class="dropdown-item pointer" (click)="generateFile('CSV')">CSV</a>
                    <a class="dropdown-item pointer" (click)="generateFile('EXCEL')">Excel</a>
                    <a class="dropdown-item pointer" (click)="generateFile('PDF')">PDF</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div class="table-responsive mt-4">
          <table class="table table-hover mg-b-0">
            <thead>
              <tr>
                <th>Attendant</th>
                <th>Product</th>
                <th>Terminal</th>
                <th>Litres</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let r of info;">
                <th>{{r.attendantName}}</th>
                <td>{{r.productName}}</td>
                <td>{{r.terminalId}}</td>
                <td>{{r.totalLitres}}</td>
                <td>{{r.totalAmount | currency: 'USD' : 'symbol': '1.2-2'}}</td>
              </tr>
            </tbody>
          </table>
        </div><!-- table-responsive -->

        <div class="ht-40"></div>

        
      </div><!-- az-content-body -->
    
    </div><!-- container -->
</div><!-- az-content -->
