<div class="az-content-left az-content-left-components">
    <div class="component-item">
      
      <label>Accounts</label> <br>
      <nav class="nav flex-column">
        <!-- <a routerLink="/admin/accounts/new" routerLinkActive="active" class="nav-link">New Account</a> -->
        <a routerLink="/admin/accounts/individual" routerLinkActive="active" class="nav-link">Individual</a>
        <a routerLink="/admin/accounts/company" routerLinkActive="active" class="nav-link">Company</a>
      </nav>

      <br><br>

      <label>Others</label> <br>
      <nav class="nav flex-column">
        <a routerLink="/admin/accounts/cards" routerLinkActive="active" class="nav-link disabled">Cards</a>
        <a routerLink="/admin/accounts/cars" routerLinkActive="active" class="nav-link disabled">Vehicles</a>
      </nav>

    </div><!-- component-item -->

</div>