<div class="az-content az-content-dashboard">
    <div class="container">
      <div class="az-content-body">
        <div class="az-dashboard-one-title">
          <div> 
            <h2 class="az-dashboard-title">Welcome back <span class="text-secondary">{{dname}}</span></h2>
            <p class="az-dashboard-text">Your account dashboard analytics.</p>
          </div>
          <div class="az-content-header-right">
            <div class="media">
              <div class="media-body">
                <label>Date</label>
                <h6>{{date}}</h6>
              </div><!-- media-body -->
            </div><!-- media -->
          </div>
        </div><!-- az-dashboard-one-title -->

        <div class="az-dashboard-nav"> 
          <nav class="nav">
            <a class="nav-link active" data-toggle="tab" href="#">Overview</a>
          </nav>
        </div>

        <div class="row row-sm mg-b-20 mg-lg-b-0">
          <div class="col-md-4">
            <div class="card h-100 card-dashboard-five">
              <div class="card-header">
                <h6 class="card-title pointer" routerLink="/admin/transactions">Monthly Sales</h6>
                <span class="card-text">Tells you about your monthly sales.</span>
              </div><!-- card-header -->
              <div class="card-body row row-sm">
                <!-- <div class="col-6 col-md-12 col-lg-6 mt-2 mt-lg-0 d-sm-flex align-items-center">
                  <div class="card-chart text-info">
                    <span class="peity-bar">
                      <i class="fa fa-2x fa-user"></i>
                    </span>
                  </div>
                  <div>
                    <label>Total Accounts</label>
                    <h4>{{indiAcc?.totalAccounts}}</h4>
                  </div>
                </div>
                <div class="col-6 col-md-12 col-lg-6 mt-2 mt-lg-0 d-sm-flex align-items-center">
                  <div class="card-chart text-success">
                    <span class="peity-bar">
                      <i class="fa fa-2x fa-dollar-sign"></i>
                    </span>
                  </div>
                  <div>
                    <label>Total Balance</label>
                    <h4>{{indiAcc?.totalBalance | currency: 'USD' : 'symbol' : '1.2-2'}}</h4>
                  </div>
                </div> -->
                <div class="col-md-12 col-lg-6" *ngFor="let r of newsales">
                  <h4>{{r.productName}}</h4>
                  <section>
                    <div class="az-list-item">
                      <div>
                        <span class="text-muted">Transaction Count</span>
                      </div>
                      <div>
                        <span class="text-info">{{r.numberOfTransactions}}</span>
                      </div>
                    </div>
                    <div class="az-list-item">
                      <div>
                        <span class="text-muted">Litres</span>
                      </div>
                      <div>
                        <span class="text-info">{{r.totalLitres}}</span>
                      </div>
                    </div>
                    <div class="az-list-item">
                      <div>
                        <span class="text-muted">Amount</span>
                      </div>
                      <div>
                        <span class="text-info">{{r.totalAmount | currency: 'USD' : 'symbol' : '1.2-2'}}</span>
                      </div>
                    </div>
                  </section>
                </div>
              </div><!-- card-body -->
            </div><!-- card-dashboard-five -->
          </div><!-- col-md-4 -->
          <div class="col-md-4 mt-3 mt-md-0">
            <div class="card h-100 card-dashboard-five">
              <div class="card-header">
                <h6 class="card-title pointer" routerLink="/admin/transactions">Daily Sales</h6>
                <span class="card-text">Tells you about your daily sales.</span>
              </div><!-- card-header -->
              <div class="card-body row row-sm">
                <!-- <div class="col-6 col-md-12 col-lg-6 mt-2 mt-lg-0 d-sm-flex align-items-center">
                  <div class="card-chart text-info">
                    <span class="peity-bar">
                      <i class="fa fa-2x fa-user"></i>
                    </span>
                  </div>
                  <div>
                    <label>Total Accounts</label>
                    <h4>{{compAcc?.totalAccounts}}</h4>
                  </div>
                </div>
                <div class="col-6 col-md-12 col-lg-6 mt-2 mt-lg-0 d-sm-flex align-items-center">
                  <div class="card-chart text-success">
                    <span class="peity-bar">
                      <i class="fa fa-2x fa-dollar-sign"></i>
                    </span>
                  </div>
                  <div>
                    <label>Total Balance</label>
                    <h4>{{compAcc?.totalBalance | currency: 'USD' : 'symbol' : '1.2-2' }}</h4>
                  </div>
                </div> -->
                <div class="col-md-12 col-lg-6" *ngFor="let r of newsales2">
                  <h4>{{r.productName}}</h4>
                  <section>
                    <div class="az-list-item">
                      <div>
                        <span class="text-muted">Transaction Count</span>
                      </div>
                      <div>
                        <span class="text-info">{{r.numberOfTransactions}}</span>
                      </div>
                    </div>
                    <div class="az-list-item">
                      <div>
                        <span class="text-muted">Litres</span>
                      </div>
                      <div>
                        <span class="text-info">{{r.totalLitres}}</span>
                      </div>
                    </div>
                    <div class="az-list-item">
                      <div>
                        <span class="text-muted">Amount</span>
                      </div>
                      <div>
                        <span class="text-info">{{r.totalAmount | currency: 'USD' : 'symbol' : '1.2-2'}}</span>
                      </div>
                    </div>
                  </section>
                </div>
              </div><!-- card-body -->
            </div><!-- card-dashboard-five -->
          </div><!-- col-md-4 -->
          <div class="col-md-2 mt-3 mt-md-0">
            <div class="card h-100 card-dashboard-five">
              <div class="card-header">
                <h6 class="card-title pointer" routerLink="/admin/accounts">Account Totals</h6>
              </div><!-- card-header -->
              <div class="card-body row row-sm">
                <canvas #pieCanvas style="height:10vh; width:20vw"></canvas>
              </div><!-- card-body -->
            </div><!-- card-dashboard-five -->
          </div><!-- col-md-2 -->
          <div class="col-md-2 h-100 mt-3 mt-md-0">
            <div class="card card-dashboard-five">
              <div class="card-header">
                <h6 class="card-title">Account Balances</h6>
              </div><!-- card-header -->
              <div class="card-body row row-sm">
                <canvas #pieCanvas2 style="height:10vh; width:20vw"></canvas>
              </div><!-- card-body -->
            </div><!-- card-dashboard-five -->
          </div><!-- col-md-2 -->
        </div><!-- row -->

        <div class="row row-sm mg-b-20 mt-4">
          <div class="col-lg-4" style="min-height: 100% !important;">
            <div class="card h-100 card-dashboard-pageviews">
              <div class="card-header">
                <h6 class="card-title">Service Station Statistics</h6>
                <p class="card-text">This report is based on service stations.</p>
              </div><!-- card-header -->
              <div class="card-body">
                <div class="az-list-item mt-4 pointer" routerLink="/admin/stations/all">
                  <div>
                    <h6>Service Stations</h6>
                    <span>&nbsp;</span>
                  </div>
                  <div>
                    <h4 class="tx-primary">{{station}}</h4>
                    <!-- <span>31.74% (-100.00%)</span> -->
                  </div>
                </div><!-- list-group-item -->
                <div class="az-list-item pointer" routerLink="/admin/stations/all">
                  <div>
                    <h6>Terminals</h6>
                    <span>&nbsp;</span>
                  </div>
                  <div>
                    <h4 class="tx-primary">{{terminals}}</h4>
                    <!-- <span>28.53% (-100.00%)</span> -->
                  </div>
                </div><!-- list-group-item -->                
                <div class="az-list-item pointer" routerLink="/admin/stations/all">
                  <div>
                    <h6>Attendants</h6>
                    <span>&nbsp;</span>
                  </div>
                  <div>
                    <h4 class="tx-primary">{{attendant}}</h4>
                    <!-- <span>28.53% (-100.00%)</span> -->
                  </div>
                </div><!-- list-group-item -->
              </div><!-- card-body -->
            </div><!-- card -->
          </div><!-- col -->
          <div class="col-lg-8 mg-t-20 mg-lg-t-0" style="height: 40vh;">
            <div class="card card-dashboard-four">
              <div class="card-body row">
                <div class="col-md-5">
                  <h6 class="card-title">Cards Statistics</h6>
                  <div class="card-body">
                    <div *ngFor="let c of cards" class="az-list-item mt-4 pointer" routerLink="/admin/settings/cards">
                      <div>
                        <h6>{{c.cardStatus}} Cards</h6>
                        <span *ngIf="c.cardStatus == 'Active'" class="badge badge-success text-white">active</span>
                        <span *ngIf="c.cardStatus == 'New'" class="badge badge-primary text-white">new</span>
                        <span *ngIf="c.cardStatus == 'Blocked'" class="badge badge-danger text-white">active</span>
                      </div>
                      <div>
                        <h6 class="tx-primary">{{c.totalCards}}</h6>
                        <span>{{c.totalBalance | currency: 'USD' : 'symbol' : '1.2-2'}}</span>
                      </div>
                    </div><!-- list-group-item -->
                  </div><!-- card-body -->
                </div><!-- card-header -->
                <div class="col-md-7 d-flex align-items-right">
                  <div class="chart col-md-8"><canvas #doughnutCanvas style="height:70%; width:70%"></canvas></div>
                </div><!-- col -->
              </div><!-- card-body -->
            </div><!-- card-dashboard-four -->
          </div><!-- col -->
        </div><!-- row -->

        <div class="row row-sm mg-b-20 mg-lg-b-0">          
          <div class="col-lg-12 mg-t-20 mg-lg-t-0" style="min-height: 40vh;">
            <div class="card card-dashboard-four">
              <div class="card-body row">
                <div class="col-md-11">
                  <h6 class="card-title">Sales Statistics</h6>
                  <div class="card-body">
                    <div *ngIf="sales" class="az-list-item">
                      <div class="table-responsive mt-4">
                        <table class="table table-hover mg-b-0">
                          <thead>
                            <tr>
                              <th>Service Station</th>
                              <th>Product</th>
                              <th>Mode</th>
                              <th>Litres</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let r of sales;">                              
                              <td>{{r.attendantName}}</td>
                              <td>{{r.productName}}</td>
                              <td>{{r.mode}}</td>
                              <td>{{r.totalLitres}}</td>
                              <td>{{r.totalAmount}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div><!-- table-responsive -->
                    </div><!-- list-group-item -->
                  </div><!-- card-body -->
                </div><!-- card-header -->
                <!-- <div class="col-md-6 mt-2 mt-md-0 align-items-right">
                  <div class="chart col-md-8"><canvas #doughnutCanvas style="height:70%; width:70%"></canvas></div>
                </div> -->
                <!-- col -->
              </div><!-- card-body -->
            </div><!-- card-dashboard-four -->
          </div><!-- col -->
        </div><!-- row -->

      </div><!-- az-content-body -->
    </div>
  </div>