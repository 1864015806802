import { ToastrService } from 'ngx-toastr';
import { AccountsService } from './../../shared/services/accounts.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StorageService } from 'src/app/shared/services/storage.service';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent implements OnInit {

  @ViewChild('doughnutCanvas') doughnutCanvas: ElementRef;
  @ViewChild('pieCanvas') private pieCanvas: ElementRef;
  @ViewChild('pieCanvas2') private pieCanvas2: ElementRef;

  pieChart: any;
  pieChart2: any;  
  doughnutChart: any;

  dname: string;
  date: string;

  load1: boolean;
  load2: boolean;

  indiAcc: any;
  compAcc: any;
  cards: any;
  sales: any;

  newsales: any;
  newsales2: any;

  station: number = 0;
  terminals: number = 0;
  attendant: number = 0;

  constructor(
    private storageService : StorageService,
    private accountsService: AccountsService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    let dn = this.storageService.decrypt(sessionStorage.getItem('display'));

    this.dname = dn ? dn : 'User';

    this.date = new Date().toUTCString().slice(0, 16);

    this.getIndividual();
    this.getCompany();
    this.getCards();
    this.getStations();
    this.getTerminals();
    this.getAttendants();
    this.getOrganisationSales();
    this.getOrganisationDailySales();
    this.getOrganisationMonthlySales();
  }

  getIndividual(){
    this.accountsService.getDashIndividualAccounts(1).subscribe(
      data => {
        // console.log(data);
        this.indiAcc = data;
        this.load1 = true;
        this.pieChartMethod();
      },
      err => {
        this.load1 = false;
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        } else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  getCompany(){
    this.accountsService.getDashCompanyAccounts(1).subscribe(
      data => {
        // console.log(data);
        this.compAcc = data;
        this.load2 = true;
        this.pieChartMethod();
      },
      err => {
        this.load2 = false;
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        } else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  getCards(){
    this.accountsService.getDashCardsStats(1).subscribe(
      data => {
        // console.log(data);
        this.cards = data;
        this.doughnutChartMethod();
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        } else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  getStations(){
    this.accountsService.getDashStationsCount(1).subscribe(
      data => {
        // console.log(data);
        this.station = data;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        } else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  getTerminals(){
    this.accountsService.getDashTerminalCount(1).subscribe(
      data => {
        // console.log(data);
        this.terminals = data;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        } else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  getAttendants(){
    this.accountsService.getDashAttendantsCount(1).subscribe(
      data => {
        // console.log(data);
        this.attendant = data;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        } else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  doughnutChartMethod() {
    let labs = [], dat = [], colors = [];

    this.cards.forEach(r => {
      labs.push(r.cardStatus);
      dat.push(r.totalCards);
      let clr = (r.cardStatus == 'Active') ? 'green' : (r.cardStatus == 'New') ? 'blue' : 'red';
      colors.push(clr);
    });

    this.doughnutChart = new Chart(this.doughnutCanvas.nativeElement, {
      type: 'doughnut',
      data: {
        labels: labs,
        datasets: [{
          label: 'Number of Accounts',
          data: dat,
          backgroundColor: colors
        }]
      }
    });
  }

  pieChartMethod() {
    if(!this.load1 || !this.load2) return false;

    let indi = this.indiAcc ? this.indiAcc.totalAccounts : 0;
    let comp = this.compAcc ? this.compAcc.totalAccounts : 0;

    this.pieChart = new Chart(this.pieCanvas.nativeElement, {
      type: 'pie',
      data: {
        labels: ['Individual', 'Company'],
        datasets: [{
          backgroundColor: [
            '#2ecc71',
            '#3498db'
          ],
          data: [indi, comp]
        }]
      }
    });

    let indi2 = this.indiAcc ? this.indiAcc.totalBalance : 0;
    let comp2 = this.compAcc ? this.compAcc.totalBalance : 0;

    this.pieChart2 = new Chart(this.pieCanvas2.nativeElement, {
      type: 'pie',
      data: {
        labels: ['Individual', 'Company'],
        datasets: [{
          backgroundColor: [
            '#2ecc71',
            '#3498db'
          ],
          data: [indi2, comp2]
        }]
      }
    });

  }

  ngAfterViewInit(): void {
    // this.barChartMethod();
  }

  getOrganisationSales(){
    this.accountsService.getDashAdminSales(1).subscribe(
      data => {
        // console.log(data);
        this.sales = data;

        // this.doughnutChartMethod();
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        } else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    );
  }

  getOrganisationMonthlySales(){
    this.accountsService.getDashAdminMonthlySales(1).subscribe(
      data => {
        // console.log(data);
        this.newsales = data;

        // this.doughnutChartMethod();
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        } else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    );
  }

  getOrganisationDailySales(){
    this.accountsService.getDashAdminDailySales(1).subscribe(
      data => {
        // console.log(data);
        this.newsales2 = data;

        // this.doughnutChartMethod();
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        } else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    );
  }

}
