<div class="az-content az-content-dashboard">
    <div class="container">
      <div class="az-content-body">
        <div class="az-dashboard-one-title">
          <div> 
            <h2 class="az-dashboard-title">Welcome back <span class="text-secondary">{{dname}}</span></h2>
            <p class="az-dashboard-text">Your account dashboard analytics.</p>
          </div>
          <div class="az-content-header-right">
            <div class="media">
              <div class="media-body">
                <label>Date</label>
                <h6>{{date}}</h6>
              </div><!-- media-body -->
            </div><!-- media -->
          </div>
        </div><!-- az-dashboard-one-title -->

        <div class="az-dashboard-nav"> 
          <nav class="nav">
            <a class="nav-link active" data-toggle="tab" href="#">Overview</a>
          </nav>
        </div>

        <div class="row row-sm mg-b-20 mg-lg-b-0">          
          <div class="col-lg-12 mg-t-20 mg-lg-t-0" style="min-height: 40vh;">
            <div class="card card-dashboard-four">
              <div class="card-body row">
                <div class="col-md-11">
                  <h6 class="card-title">Sales Statistics</h6>
                  <div class="card-body">
                    <div *ngIf="sales" class="az-list-item">
                      <div class="table-responsive mt-4">
                        <table class="table table-hover mg-b-0">
                          <thead>
                            <tr>
                              <th>Attendant</th>
                              <th>Product</th>
                              <th>Mode</th>
                              <th>Terminal</th>
                              <th>Litres</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let r of sales;">
                              <th>{{r.attendantName}}</th>
                              <td>{{r.productName}}</td>
                              <td>{{r.mode}}</td>
                              <td>{{r.terminalId}}</td>
                              <td>{{r.totalLitres}}</td>
                              <td>{{r.totalAmount}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div><!-- table-responsive -->
                    </div><!-- list-group-item -->
                  </div><!-- card-body -->
                </div><!-- card-header -->
                <!-- <div class="col-md-6 mt-2 mt-md-0 align-items-right">
                  <div class="chart col-md-8"><canvas #doughnutCanvas style="height:70%; width:70%"></canvas></div>
                </div> -->
                <!-- col -->
              </div><!-- card-body -->
            </div><!-- card-dashboard-four -->
          </div><!-- col -->
        </div><!-- row -->

      </div><!-- az-content-body -->
    </div>
  </div>