<div class="az-content pd-y-20 pd-lg-y-30 pd-xl-y-40">
    <div class="container">
        <app-sidebar-stations></app-sidebar-stations>
        <!-- az-content-left -->
      
      <div class="az-content-body pd-lg-l-40 d-flex flex-column">
        <div class="az-content-breadcrumb">
          <span>Stations</span>
          <span>Service</span>
          <span>Stations</span>
        </div>
        <h2 class="az-content-title">Service Stations</h2>

        <div class="row">
          <div class="col-md-8">
            <div class="az-content-label mg-b-5">All Stations List</div>
            <p class="mg-b-20">List of all registered Service Stations.</p>
          </div>
          <div class="col-md-4 mt-md-0 mt-3" align="right">
            <button class="btn btn-outline-success btn-rounded btn-sm" data-toggle="modal" data-target="#add">
              NEW &nbsp;<i class="fa fa-plus-circle"></i>
            </button>
          </div>
        </div>

        <div class="table-responsive mt-4">
          <table class="table table-hover mg-b-0">
            <thead>
              <tr>
                <th>Organisation</th>
                <th>Station Name</th>
                <th>Station Type</th>
                <th>Contact</th>
                <th>Address</th>
                <th>Management</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let r of stations;">
                <th>{{r.owner.organisation}}</th>
                <td>{{r.stationName}}</td>
                <td>{{r.stationType}}</td>
                <td>{{r.contactNumber}}</td>
                <td>{{r.address}}</td>
                <td>
                  <span class="text-info pointer" title="Manage Attendants" (click)="navigateToPage('/admin/stations/attendants/' + r.id)">
                    <i class="fa fa-users-cog"></i>
                  </span>
                  <span class="text-info pointer ml-2" title="Manage Products" (click)="navigateToPage('/admin/stations/products/' + r.id)">
                    <i class="fa fa-list"></i>
                  </span>
                  <span class="text-info pointer ml-2" title="Manage Terminals" (click)="navigateToPage('/admin/stations/terminals/' + r.id)">
                    <i class="fa fa-mobile-alt"></i>
                  </span>
                  <span class="text-info pointer ml-2" title="View Station Transactions" (click)="viewTransactions(r.id, r.stationName)">
                    <i class="fa fa-money-bill-wave"></i>
                  </span>
                </td>
                <td>
                  <span class="text-primary pointer" title="Update Record" (click)="loadStation(r.id)">
                    <i class="fa fa-edit"></i>
                  </span>
                  <span class="text-danger pointer ml-2" title="Delete Record" (click)="selectStation(r.id)">
                    <i class="fa fa-trash-alt"></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div><!-- table-responsive -->

        <div class="ht-40"></div>

        
      </div><!-- az-content-body -->
    
    </div><!-- container -->
</div><!-- az-content -->

<!-- Modals -->
<div class="modal fade" id="add" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">CREATE STATION</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <form [formGroup]="addForm" (ngSubmit)="addStation(addForm)">
            <div class="container">
              <div class="row">
                <!-- <div class="col-md-12">
                    <div class="form-group">
                      Organisation
                      <select formControlName="organisationId" class="form-control" required>
                        <option value="" selected disabled>choose...</option>
                        <option *ngFor="let o of owners" value="{{o.id}}">{{o.organisation}}</option>
                      </select>
                    </div>
                </div> -->
                <div class="col-md-12">
                  <div class="form-group">
                    Station Type
                    <select formControlName="stationType" class="form-control" required>
                      <option value="" selected disabled>choose...</option>
                      <option value="AGENT">Agent</option>
                      <option value="DEALERSHIP">Dealership</option>
                      <option value="PETROTRADE">Petrotrade</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    Station Name
                    <input type="text" formControlName="stationName" placeholder="Station Name" required class="form-control">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    Contact Number
                    <input type="text" formControlName="contactNumber" placeholder="Contact Number" required class="form-control">
                  </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                      Address
                      <textarea formControlName="address" cols="30" rows="5" required class="form-control" placeholder="Type here..."></textarea>
                    </div>
                </div>
              </div>
            </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-danger" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-primary" type="submit"><i class="fa fa-save"></i>&nbsp; Add Station</button>
            </div>
          </form>
        </section>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="edit" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">UPDATE STATION</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <form [formGroup]="editForm" (ngSubmit)="updateStation(editForm)">
            <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      Station Type
                      <select formControlName="stationType" class="form-control" required>
                        <option value="" selected disabled>choose...</option>
                        <option value="AGENT">Agent</option>
                        <option value="DEALERSHIP">Dealership</option>
                        <option value="PETROTRADE">Petrotrade</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      Station Name
                      <input type="text" formControlName="stationName" placeholder="Station Name" required class="form-control">
                    </div>
                  </div>
                  <div class="col-md-12">
                      <div class="form-group">
                        Address
                        <textarea formControlName="address" cols="30" rows="5" required class="form-control" placeholder="Type here..."></textarea>
                      </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      Contact Number
                      <input type="text" formControlName="contactNumber" placeholder="Contact Number" required class="form-control">
                    </div>
                  </div>
                </div>
              </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-danger" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-primary" type="submit"><i class="fa fa-save"></i>&nbsp; Update Station</button>
            </div>
          </form>
        </section>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">DELETE STATION</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <div>
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h2>You are about to delete a Station!</h2>
                </div>
              </div>
            </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-primary" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-danger" type="button" (click)="deleteStation()"><i class="fa fa-trash-alt"></i>&nbsp; Delete Station</button>
            </div>
          </div>
        </section>
      </div>      
    </div>
  </div>
</div>