import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import * as fileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TransactionsService } from 'src/app/shared/services/transactions.service';
declare var $: any;

@Component({
  selector: 'app-supervisor-trans',
  templateUrl: './supervisor-trans.component.html',
  styleUrls: ['./supervisor-trans.component.css']
})
export class SupervisorTransComponent implements OnInit {

  station: any;

  info: any;
  payload: any;

  constructor(
    private transactionService: TransactionsService,
    private toast: ToastrService,
    private storageService: StorageService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.station = this.storageService.decrypt(sessionStorage.getItem('agent_id'));

    // console.log(this.accType, this.accNum);
    
    this.payload = {
      "stationId": this.station,
      "startDate": "2023-01-01",
      "endDate": "2023-12-31"
    };

    if(this.station) this.getAccTransactions();

  }

  getAccTransactions(){    

    this.transactionService.getSupervisorTransactions(this.payload).subscribe(
      data => {
        // console.log(data);
        this.info = data;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        }else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permissions to access this Resource.");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    );
  }

  generateFile(typ){
    if(!typ){
      this.toast.warning('Choose file type.');
      return false;
    }

    let d1 = $('#startDate').val();
    let d2 = $('#endDate').val();

    if(!d1){
      $('#startDate').addClass('invalid');
      return false;
    }
    else{
      $('#startDate').removeClass('invalid');
    }

    if(!d2){
      $('#endDate').addClass('invalid');
      return false;
    }
    else{
      $('#endDate').removeClass('invalid');
    }

    let info = {
      ref: this.station,
      startDate: this.datePipe.transform(d1, 'dd/MM/yyyy'),
      endDate: this.datePipe.transform(d2, 'dd/MM/yyyy'),
      typ: typ
    };

    this.transactionService.exportStationSales(info.ref, info.typ, info.startDate, info.endDate).subscribe(
      resp => {
        fileSaver.saveAs(resp, `Station transactions summary ${d1} - ${d2}.${typ.toLowerCase()}`);
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        }else if(err.status == 500){
          this.toast.error('Internal Server Error')
        }
        else{
          this.toast.error('Request Failed: ' + err.status);
        }
      }
    );

  }

  handleDateSelection(){
    let d1 = $('#startDate').val();
    let d2 = $('#endDate').val();

    if(!d1){
      $('#startDate').addClass('invalid');
      return false;
    }
    else{
      $('#startDate').removeClass('invalid');
    }

    if(!d2){
      $('#endDate').addClass('invalid');
      return false;
    }
    else{
      $('#endDate').removeClass('invalid');
    }

    this.payload.startDate = d1;
    this.payload.endDate = d2;

    this.getAccTransactions();

  }

}
