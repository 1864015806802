import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../../services/storage.service';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isSelected: string = '';
  dname: string;
  uname: string;
  userType: string;
  accType: string;

  constructor(
    private authService: AuthService,
    private router : Router,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    let myPath = window.location.pathname;

    // determine selection
    myPath.includes('home') ? this.isSelected = 'home' :
      myPath.includes('stations') ? this.isSelected = 'stations' : 
      myPath.includes('accounts') ? this.isSelected = 'accounts' : 
      myPath.includes('transactions') ? this.isSelected = 'transactions' : 
      myPath.includes('users') ? this.isSelected = 'users' : 
      myPath.includes('settings') ? this.isSelected = 'settings' : false;

    let dn = this.authService.getUserDisplayName();
    let un = this.authService.getUserName();
    let ut = this.authService.getUserType();    

    this.dname = dn ? dn : 'User';
    this.uname = un ? un : 'Username';
    this.userType = ut ? ut : 'admin';

    this.accType = this.storageService.decrypt(sessionStorage.getItem('acc_type'));
    // console.log(ut);
    

    $('#azMenuShow').on('click', function(e){
      e.preventDefault();
      $('body').toggleClass('az-header-menu-show');
    })
  }

  setSelection(ref){
    this.isSelected = ref;
  }

  doLogout(){
    this.authService.doLogout() ?  this.router.navigateByUrl('/') : false;
  }

}
