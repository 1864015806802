<section class="az-body">

    <div class="az-signin-wrapper">
      <div class="az-card-signin">
        <!-- <h1 class="az-logo">Thuli <span>&nbsp;Admin</span></h1> -->
        <figure class="text-center">
          <img src="/assets/img/logo.png" class="w-75" alt="PetroTrade Logo">
        </figure>
        <div class="az-signin-header">
          <h2>Welcome back!</h2>
          <h4>Please sign in to continue</h4>

          <form [formGroup]="authForm" (ngSubmit)="userLogin(authForm)">
            <div class="form-group">
              <label>Username</label>
              <input formControlName="username" required type="text" class="form-control" placeholder="Enter your username">
            </div><!-- form-group -->
            <div class="form-group">
              <label>Password</label>
              <input formControlName="password" required type="password" class="form-control" placeholder="Enter your password">
            </div><!-- form-group -->
            <button type="submit" class="btn btn-az-primary btn-block">Sign In</button>
          </form>
        </div><!-- az-signin-header -->
        <div class="az-signin-footer">
          <p class="mt-2"><a routerLink="/reset-password">Forgot password?</a></p>
          <!-- <p>Don't have an account? <a routerLink="/self-register">Create an Account</a></p> -->
        </div><!-- az-signin-footer -->
      </div><!-- az-card-signin -->
    </div><!-- az-signin-wrapper -->

  </section>