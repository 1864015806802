import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/shared/services/accounts.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-supervisor-home',
  templateUrl: './supervisor-home.component.html',
  styleUrls: ['./supervisor-home.component.css']
})
export class SupervisorHomeComponent implements OnInit {

  @ViewChild('doughnutCanvas') doughnutCanvas: ElementRef;
  doughnutChart: any;
  
  dname: string;
  date: string;
  atype: string;
  sales: any;
  accInfo: any;
  station: number;

  constructor(
    private storageService : StorageService,
    private accountsService: AccountsService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    let dn = this.storageService.decrypt(sessionStorage.getItem('display'));
    let at = this.storageService.decrypt(sessionStorage.getItem('acc_type'));
    let st = this.storageService.decrypt(sessionStorage.getItem('agent_id'));

    this.dname = dn ? dn : 'User';
    this.atype = at ? at : '';
    this.station = st ? st : 0;

    this.date = new Date().toUTCString().slice(0, 16);

    this.getStationSales();
  }

  getStationSales(){
    if(this.station){
      this.accountsService.getDashSupervisorSales(this.station).subscribe(
        data => {
          // console.log(data);
          this.sales = data;

          // this.doughnutChartMethod();
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          } else{
            this.toast.error('An Error Occured. Contact System Admin');
          }
        }
      );
    }
  }

  doughnutChartMethod() {
    let labs = [], dat = [], colors = [];

    this.sales.forEach(r => {
      labs.push(r.attendantName + ' (' + r.productName +')');
      dat.push(r.amount);
      // let clr = (r.cardStatus == 'Active') ? 'green' : (r.cardStatus == 'New') ? 'blue' : 'red';
      // colors.push(clr);
    });

    this.doughnutChart = new Chart(this.doughnutCanvas.nativeElement, {
      type: 'doughnut',
      data: {
        labels: labs,
        datasets: [{
          label: 'Sales by Attendant',
          data: dat,
          // backgroundColor: colors
        }]
      }
    });
  }


}
