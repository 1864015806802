<section class="az-body">

    <div class="az-signin-wrapper">
      <section *ngIf="token">
        <div class="az-card-signin">
            <!-- <h1 class="az-logo">Thuli <span>&nbsp;Admin</span></h1> -->
            <figure class="text-center">
              <img src="/assets/img/logo.png" class="w-75" alt="PetroTrade Logo">
            </figure>
            <div class="az-signin-header">
              <h2>Change Password</h2>
              <h4>Enter new password</h4>
    
              <form [formGroup]="changeForm" (submit)="changePassword(changeForm)">
                <div class="form-group">
                  <label>Password</label>
                  <input type="password" formControlName="newPassword" class="form-control" placeholder="Enter your password">
                </div>
                <div class="form-group">
                  <label>Confirm Password</label>
                  <input type="password" class="form-control" placeholder="Enter your password">
                </div><!-- form-group -->
                <button class="btn btn-az-primary btn-block" type="submit">Change Password</button>
              </form>
            </div><!-- az-signin-header -->
            <div class="az-signin-footer">
                <!-- <p>Remember your password? <a routerLink="/login">Sign In</a></p> -->
            </div><!-- az-signin-footer -->
        </div>
      </section>

      <section *ngIf="!token">
        <div class="az-card-signin">
            <!-- <h1 class="az-logo">Thuli <span>&nbsp;Admin</span></h1> -->
            <figure class="text-center">
              <img src="/assets/img/logo.png" class="w-75" alt="PetroTrade Logo">
            </figure>
            <div class="az-signin-header">
              <h2>Password Reset</h2>
              <h4>Enter email to continue</h4>
    
              <form onsubmit="return false;">
                <div class="form-group">
                  <label>Email</label>
                  <input type="text" id="myEmail" class="form-control" placeholder="Enter your email">
                </div><!-- form-group -->
                <button class="btn btn-az-primary btn-block" type="button" (click)="sendToken()">Submit Email</button>
              </form>
            </div><!-- az-signin-header -->
            <div class="az-signin-footer">
              <p>Remember your password? <a routerLink="/login">Sign In</a></p>
            </div><!-- az-signin-footer -->
        </div>
      </section>
    </div><!-- az-signin-wrapper -->

  </section>