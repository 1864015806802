import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment.prod';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  baseURL: string = environment.baseURL + '/akupay-main-service/api/v1';

  constructor(
    private http : HttpClient
  ) { }

  handleStationTransactionRequest(typ, data): Observable<any>{
    let resp;

    if(typ == 'station')
      resp = this.getStationTransactions(data);
    else if(typ == 'terminal')
      resp = this.getTerminalTransactions(data);
    else if(typ == 'attendant')
      resp = this.getAttendantTransactions(data);

    return resp;
  }

  handleAccountsTransactionRequest(typ, data): Observable<any>{
    let resp;

    if(typ == 'card')
      resp = this.getCardTransactions(data);
    else if(typ == 'account')
      resp = this.getAccountTransactions(data);

    return resp;
  }

  exportStationTransactionRequest(typ, data): Observable<any>{
    let resp;

    if(typ == 'station')
      resp = this.exportStationTransactions(data.ref, data.org, data.typ, data.startDate, data.endDate);
    else if(typ == 'terminal')
      resp = this.exportTerminalTransactions(data.ref, data.org, data.typ, data.startDate, data.endDate);
    else if(typ == 'attendant')
      resp = this.exportAttendantTransactions(data.ref, data.org, data.typ, data.startDate, data.endDate);

    return resp;
  }

  exportAccountsTransactionRequest(typ, data): Observable<any>{
    let resp;

    if(typ == 'card')
      resp = this.exportCardTransactions(data.ref, data.org, data.typ, data.startDate, data.endDate);
    else if(typ == 'account')
      resp = this.exportAccountTransactions(data.ref, data.org, data.typ, data.startDate, data.endDate);

    return resp;
  }

  // account transactions
  getAccountTransactions(data): Observable<any> {
    return this.http.post(`${this.baseURL}/reports/account`, data);
  }

  getTerminalTransactions(data): Observable<any> {
    return this.http.post(`${this.baseURL}/reports/terminal`, data);
  }

  getStationTransactions(data): Observable<any> {
    return this.http.post(`${this.baseURL}/reports/station`, data);
  }

  getSupervisorTransactions(data): Observable<any> {
    return this.http.post(`${this.baseURL}/reports/sales/station`, data);
  }

  getOrgTransactionsSummary(data): Observable<any> {
    return this.http.post(`${this.baseURL}/reports/sales/organisation`, data);
  }

  getOrganisationTransactions(data): Observable<any> {
    return this.http.post(`${this.baseURL}/reports/organisation`, data);
  }

  getCardTransactions(data): Observable<any> {
    return this.http.post(`${this.baseURL}/reports/card`, data);
  }

  getAttendantTransactions(data): Observable<any> {
    return this.http.post(`${this.baseURL}/reports/attendant`, data);
  }

  getAccountPending(data): Observable<any> {
    return this.http.post(`${this.baseURL}/reports/account-pending`, data);
  }

  getOrganisationPending(data): Observable<any> {
    return this.http.post(`${this.baseURL}/reports/organisation-pending`, data);
  }

  // export transactions
  exportTerminalTransactions(ref, org, typ, start, end): Observable<any> {
    return this.http.get(`${this.baseURL}/reports/transactions-by-terminal?terminalId=${ref}&organisation=${org}&reportType=${typ}&fromDate=${start}&toDate=${end}`, {responseType: 'blob'});
  }

  exportStationTransactions(ref, org, typ, start, end): Observable<any> {
    return this.http.get(`${this.baseURL}/reports/transactions-by-station?stationId=${ref}&organisation=${org}&reportType=${typ}&fromDate=${start}&toDate=${end}`, {responseType: 'blob'});
  }

  exportStationSales(ref, typ, start, end): Observable<any> {
    return this.http.get(`${this.baseURL}/reports/sales-by-station?stationId=${ref}&reportType=${typ}&fromDate=${start}&toDate=${end}`, {responseType: 'blob'});
  }

  exportOrganisationSales(ref, typ, start, end): Observable<any> {
    return this.http.get(`${this.baseURL}/reports/sales-by-organisation?organisationId=${ref}&reportType=${typ}&fromDate=${start}&toDate=${end}`, {responseType: 'blob'});
  }

  exportOrganisationTransactions(org, typ, start, end): Observable<any> {
    return this.http.get(`${this.baseURL}/reports/transactions-by-organisation?organisation=${org}&reportType=${typ}&fromDate=${start}&toDate=${end}`, {responseType: 'blob'});
  }

  exportCardTransactions(ref, org, typ, start, end): Observable<any> {
    return this.http.get(`${this.baseURL}/reports/transactions-by-card?cardNumber=${ref}&organisation=${org}&reportType=${typ}&fromDate=${start}&toDate=${end}`, {responseType: 'blob'});
  }

  exportAttendantTransactions(ref, org, typ, start, end): Observable<any> {
    return this.http.get(`${this.baseURL}/reports/transactions-by-attendant?attendantId=${ref}&organisation=${org}&reportType=${typ}&fromDate=${start}&toDate=${end}`, {responseType: 'blob'});
  }

  exportAccountTransactions(ref, org, typ, start, end): Observable<any> {
    return this.http.get(`${this.baseURL}/reports/transactions-by-account?accountNumber=${ref}&organisation=${org}&reportType=${typ}&fromDate=${start}&toDate=${end}`, {responseType: 'blob'});
  }

  // export group report
  exportGroupTransactions(org, typ, start, end): Observable<any> {
    return this.http.get(`${this.baseURL}/reports/transactions-group-by-station?organisation=${org}&reportType=${typ}&fromDate=${start}&toDate=${end}`, {responseType: 'blob'});
  }

}
