import { MngAccCardsComponent } from './mng-acc-cards/mng-acc-cards.component';
import { MngVehicleComponent } from './mng-vehicle/mng-vehicle.component';
import { MngCardComponent } from './mng-card/mng-card.component';
import { MngCompanyComponent } from './mng-company/mng-company.component';
import { MngIndividualComponent } from './mng-individual/mng-individual.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountTransactionsComponent } from './account-transactions/account-transactions.component';
import { MngManagersComponent } from '../admin-users/mng-managers/mng-managers.component';
import { NewAccComponent } from './new-acc/new-acc.component';

const routes: Routes = [
  { path: '', redirectTo: 'individual', pathMatch: 'full' },
  { path: 'new', component: NewAccComponent },
  { path: 'individual', component: MngIndividualComponent },
  { path: 'company', component: MngCompanyComponent },
  { path: 'company/managers/:id', component: MngManagersComponent },
  { path: 'cards/:id', component: MngAccCardsComponent },
  { path: 'cars/:id', component: MngVehicleComponent },
  { path: 'transactions/:id', component: AccountTransactionsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminAccountsRoutingModule { }
